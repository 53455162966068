import classNames from "classnames";
import {HTMLProps} from "react";
import {isExternalURL} from "@/components/atoms/link";

type BodyProps = {
  bodyType?: "sm" | "lg",
  elementType?: "span" | "div" | "p" | "a",
} & HTMLProps<any>

/**
 * Stylized body text.
 *
 * @param bodyType
 *   A body type "lg" or "sm" or empty for regular.
 * @param elementType
 *   An element type "span" or "div" or "p".
 * @param lineHeight
 *   An optional line height string
 * @param props
 *   HTML Props.
 * @constructor
 */
export function Body({bodyType, elementType = "div", ...props}: BodyProps) {
  const className = props.className ?? "";
  delete(props.className);
  return (
    <>
      {elementType === "div" && (
        <div className={classNames(
          {
            "text-base": !bodyType,
            "text-base md:text-xl max-md:leading-body-lg": bodyType === "lg",
            "text-xs": bodyType === "sm",
          },
          className ?? ""
        )} {...props}>{props.children}</div>
      )}
      {elementType === "p" && (
        <p className={classNames(
          {
            "text-base": !bodyType,
            "text-base md:text-xl max-md:leading-body-lg": bodyType === "lg",
            "text-xs": bodyType === "sm",
          },
          className ?? ""
        )} {...props}>{props.children}</p>
      )}
      {elementType === "a" && isExternalURL(props?.href ? props?.href : "") && (
        <a href={props.href} {...props} target="_blank" rel="noopener noreferrer">{props.children}</a>
      )}
      {elementType === "a" && !isExternalURL(props?.href ? props?.href : "") && (
        <a href={props.href} {...props}>{props.children}</a>
      )}
      {
        elementType === "span" && (
          <span className={classNames(
                {
                  "text-base": !bodyType,
                  "text-base md:text-xl max-md:leading-body-lg": bodyType === "lg",
                  "text-xs": bodyType === "sm",
                },
                className ?? ""
              )} {...props}>{props.children}</span>
      )}
    </>
  )
}