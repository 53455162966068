import {CSSProperties, HTMLProps} from "react";
import {Link} from "../atoms/link";
import classNames from "classnames";
import {Heading} from "../atoms/heading";
import {Body} from "../atoms/body";

interface SectionHeadingProps extends HTMLProps<HTMLDivElement> {
  title?: string,
  displayTitle?: string,
  headingClassName?: string,
  marginBottom?: string,
  link?: {
    uri: string
    url?: string
    title: string
  },
  headerType?: number,
  weight?: string,
}

/**
 * Display a section heading with title, link and child content which should either be text or a FormattedText element.
 * @param title
 *   The title text.
 * @param link
 *   The optional link.
 * @param displayTitle
 *   An optional display title that will be parsed from html.
 * @param headerType
 *   The header type can be either 2,3,4,5 or 6.
 * @param weight
 *   The font weight.
 * @param headingClassName
 *   The class name for the heading.
 * @param marginBottom
 *   Optional bottom margin in rems.
 * @param props
 * @constructor
 */
export default function SectionHeading({title, link, displayTitle, headerType, weight, headingClassName, marginBottom, ...props}: SectionHeadingProps) {
  const type = headerType ?? 2;
  const style: CSSProperties = {};

  if (marginBottom) {
    style.marginBottom = `${marginBottom}rem !important`;
  }

  return (
    <div className={classNames("section-heading mb-6 text-black", props?.className ?? "")} id={props?.id} {...props}>
      <div className={"flex items-baseline flex-wrap"} style={style}>
        <Heading headerType={type} className={classNames(
          "font-bold w-full md:w-auto text-black",
          headingClassName ?? "",
          {"font-bold": !weight || weight === "bold", "font-light": weight === "light", "font-normal": weight === "normal"},
        )}>{displayTitle ? <span dangerouslySetInnerHTML={{__html: displayTitle}}/> : title}</Heading>
        {(link && (link?.url || link?.uri)) && (
          <Link href={link?.url ?? link?.uri ?? "/"} className="text-primary inline md:pl-3 mt-0.5 md:mt-0">{link.title} <span>&#8594;</span></Link>
        )}
      </div>
      <Body elementType={"div"} bodyType="lg" className="text-black leading-8 font-light prose">
        {props.children}
      </Body>
    </div>
  )
}